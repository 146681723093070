/* global $ */

const submenuEl = document.querySelectorAll('.submenu');
submenuEl.forEach((submenu) => {
  const submenuTitleEl = submenu.querySelectorAll('.submenu-title');
  submenuTitleEl.forEach((submenuTitle) => {
    submenuTitle.addEventListener('click', (evt) => {
      const target = evt.currentTarget;
      const targetParent = target.parentNode;
      if (targetParent.classList.contains('retracted')) {
        targetParent.classList.remove('retracted');
      } else {
        targetParent.classList.add('retracted');
      }
    });
  });

  const submenuItemEl = submenu.querySelectorAll('.submenu-item[data-sublist="1"]');

  submenuItemEl.forEach((submenuItem) => {
    const navLink = submenuItem.querySelector('.nav-link');

    navLink.addEventListener('click', (evt) => {
      evt.preventDefault();
      if (!submenuItem.classList.contains('active')) {
        submenuItemEl.forEach((oo) => {
          oo.classList.remove('active');
        });
        submenuItem.classList.add('active');
      } else {
        submenuItem.classList.remove('active');
      }
    });
  });
});

let didScroll;
let lastScrollTop = 0;
const delta = 5;
const header = $('.header');
const actions = $('.actions');
const navbarHeight = header.outerHeight();

const hasScrolled = () => {
  const st = $(window).scrollTop();

  if (st <= 0) {
    header.removeClass('nav-show').removeClass('nav-hide');
    actions.removeClass('actions-show').removeClass('actions-hide');
    return;
  }

  // Make sure they scroll more than delta
  if (Math.abs(lastScrollTop - st) <= delta) return;

  // If they scrolled hide and are past the navbar, add class .nav-show.
  // This is necessary so you never see what is "behind" the navbar.
  if (st > lastScrollTop && st > navbarHeight) {
    // Scroll hide
    header.removeClass('nav-show').addClass('nav-hide');
    actions.removeClass('actions-show').addClass('actions-hide');
  } else if (st + $(window).height() < $(document).height()) {
    header.removeClass('nav-hide').addClass('nav-show');
    actions.removeClass('actions-hide').addClass('actions-show');
  }

  lastScrollTop = st;
};

$(window).scroll(() => {
  didScroll = true;
});

setInterval(() => {
  if (didScroll) {
    hasScrolled();
    didScroll = false;
  }
}, 150);
