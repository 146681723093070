/* global $ */

import { openOverlay, closeOverlay } from './utils';

// Menu toggle and sidebar behaviour
const blogSidebarToggle = $('.blog-sidebar-toggle');
const sidebar = $('.blog-sidebar');
const sidebarClose = $('.blog-sidebar-close');

blogSidebarToggle.click((evt) => {
  evt.preventDefault();
  sidebar.addClass('active');
  $('.product-filters').removeClass('active');
  openOverlay();
});

sidebarClose.click((evt) => {
  evt.preventDefault();
  sidebar.removeClass('active');
  $('.submenu').removeClass('active');
  closeOverlay();
});

const menuLinks = $('.blog-sidebar-list a');
const menuBacks = $('.blog-sidebar-sublist-back');

menuLinks.click((evt) => {
  const target = evt.currentTarget;
  const next = $(target).next();

  if (next.hasClass('blog-sidebar-sublist')) {
    evt.preventDefault();
    next.addClass('active');
  }
});

menuBacks.click((evt) => {
  evt.preventDefault();
  const target = evt.currentTarget;
  const parentSubmenu = $(target).closest('.blog-sidebar-sublist');

  parentSubmenu.removeClass('active');
});

const submenuEl = document.querySelectorAll('.blog-sidebar-sublist');
submenuEl.forEach((submenu) => {
  const submenuItemEl = submenu.querySelectorAll('.blog-sidebar-sublist-item[data-sublist="1"]');

  submenuItemEl.forEach((submenuItem) => {
    const navLink = submenuItem.querySelector('a');

    navLink.addEventListener('click', (evt) => {
      evt.preventDefault();
      if (!submenuItem.classList.contains('active')) {
        submenuItemEl.forEach((oo) => {
          oo.classList.remove('active');
        });
        submenuItem.classList.add('active');
      } else {
        submenuItem.classList.remove('active');
      }
    });
  });
});
