/* eslint-disable no-unused-vars */
/* global $, bootstrap, YT, AOS */

import './header';
import './menu_sidebar';
import './blog_sidebar';
import './product_filters';
import './accordion';
import './carousels';
import './modal';
import './video';
// import './rotator';
import './textfield';

window.addEventListener('load', () => {
  AOS.init({
    easing: 'ease-in-out',
    once: true,
    duration: 1000,
  });

  $('.img-zoom').zoom();

  // Activate popovers
  const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]:not(.figure)'));
  popoverTriggerList.map((popoverTriggerEl) => new bootstrap.Popover(popoverTriggerEl));

  // Activate Tooltips
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]:not(.js-region)'));
  tooltipTriggerList.map((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl));

  // Select
  const selectWrapper = document.querySelectorAll('.select-wrapper');
  selectWrapper.forEach((o) => {
    const selectTrigger = o.querySelector('.select__trigger');
    selectTrigger.addEventListener('click', (evt) => {
      const target = evt.currentTarget;
      target.closest('.select').classList.add('open');
    });
  });

  const customOption = document.querySelectorAll('.custom-option');
  customOption.forEach((option) => {
    const selectWrapperParent = option.closest('.select-wrapper');
    option.addEventListener('click', (evt) => {
      const target = evt.currentTarget;
      if (!target.classList.contains('selected')) {
        const selected = target.parentNode.querySelector('.custom-option.selected');
        if (selected) {
          selected.classList.remove('selected');
        }
        if (selectWrapperParent.dataset.trigger !== undefined) {
          document.querySelector(`#${selectWrapperParent.dataset.trigger} #${target.dataset.value}`).click();
        }
        target.classList.add('selected');
        target.closest('.select').querySelector('.select__trigger span').textContent = target.textContent;
        target.closest('.select').classList.remove('open');
      }
    });
  });

  window.addEventListener('click', (evt) => {
    const select = document.querySelectorAll('.select');
    select.forEach((o) => {
      if (!o.contains(evt.target)) {
        o.classList.remove('open');
      }
    });
  });

  // const tabEl = document.querySelectorAll('button[data-bs-toggle="tab"]');
  // tabEl.forEach((o) => {
  //   o.addEventListener('shown.bs.tab', (event) => {
  //     $('.slick-initialized').slick('refresh');
  //   });
  // });

  const jcSelect = document.querySelectorAll('.jc-select');
  jcSelect.forEach((o) => {
    const $this = $(o);
    const $placeholder = $this.attr('placeholder');
    if ($placeholder) {
      $this.select2({
        placeholder: $placeholder,
        theme: 'jc-select2',
      });
    } else {
      $this.select2();
    }
  });
});
