/* global $ */

import {
  openOverlay, closeOverlay,
} from './utils';

// Product filters behaviour
const ingredients = $('.ingredients');

ingredients.each((k, i) => {
  const seeker = $(i).find('.ingredients-seeker');
  const list = $(i).find('.ingredients-list li');
  const filterAll = $(i).find('.ingredients-list li .filter-all[type="checkbox"]');
  const filters = $(i).find('.ingredients-list li input[type="checkbox"]');

  seeker.keyup(() => {
    list.each((l, j) => {
      $(j).removeClass('visually-hidden');
      const expr = new RegExp(`^${seeker.val()}`, 'i');
      if (!expr.test($(j).find('label').text())) {
        $(j).addClass('visually-hidden');
      }
    });
  });

  filterAll.change((evt) => {
    const self = $(evt.target);
    if (self.is(':checked')) {
      filters.prop('checked', true);
    } else {
      filters.prop('checked', false);
    }
  });

  filters.change((evt) => {
    const self = $(evt.target);
    if (!self.is(':checked')) {
      filterAll.prop('checked', false);
    }
  });
});

// Product filters behaviour
const filtersToggle = $('.product-filters-toggle');
const filters = $('.product-filters');
const filtersClose = $('.product-filters-close');

filtersToggle.click((evt) => {
  evt.preventDefault();
  filters.addClass('active');
  $('.header').removeClass('nav-down').addClass('nav-up');
  openOverlay();
});

filtersClose.click((evt) => {
  evt.preventDefault();
  filters.removeClass('active');
  // $('.header').removeClass('nav-up').addClass('nav-down');
  closeOverlay();
});
