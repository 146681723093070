/* global $, videojs */

// // eslint-disable-next-line import/no-mutable-exports
// let player;

// window.addEventListener('load', () => {
//   player = new YT.Player('player', {
//     videoId: 'GG5xBwbje1E',
//   });
// });

// // eslint-disable-next-line import/prefer-default-export
// export { player };

window.addEventListener('load', () => {
  if($('#my-player').length > 0){
    const player = videojs('my-player');
    const modals = $('.modal');

    // modals.on('show.bs.modal', () => {
    //   player.play();
    // });

    modals.on('hidden.bs.modal', () => {
      player.pause();
    });
  }
});
