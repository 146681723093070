// Owl Carousel behaviour
/* global $ */

window.addEventListener('load', () => {
  $('.carousel-big-banners').owlCarousel({
    smartSpeed: 1000,
    loop: true,
    navText: [
      '<span></span>',
      '<span></span>',
    ],
    items: 1,
    dots: true,
    center: true,
    // autoplay: true,
    margin: 10,
    responsive: {
      0: {
        nav: false,
      },
      991: {
        nav: true,
      },
    },
  });

  $('.carousel-banners').owlCarousel({
    smartSpeed: 1000,
    loop: true,
    nav: true,
    navText: [
      '<span></span>',
      '<span></span>',
    ],
    responsive: {
      0: {
        dots: true,
        nav: false,
        items: 1,
      },
      991: {
        dots: false,
        nav: true,
        items: 1,
      },
    },
    margin: 30,
  });

  $('.carousel-cards').owlCarousel({
    smartSpeed: 1000,
    loop: true,
    nav: true,
    navText: [
      '<span></span>',
      '<span></span>',
    ],
    responsive: {
      0: {
        dots: true,
        nav: false,
        items: 1,
        stagePadding: 30,
        margin: 20,
        center: true,
      },
      991: {
        dots: true,
        nav: true,
        items: 3,
        margin: 30,
      },
    },
  });

  $('.carousel-clients').owlCarousel({
    nav: false,
    dots: false,
    items: 4,
    slideBy: 1,
    smartSpeed: 1000,
    loop: true,
    autoplay: true,
    slideTransition: 'linear',
    autoplayTimeout: 3000,
    autoplaySpeed: 3000,
    // autoplayHoverPause: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    margin: 20,
    responsive: {
      0: {
        items: 3,
      },
      991: {
        items: 4,
      },
    },
  });

  $('.carousel-brands').owlCarousel({
    smartSpeed: 1000,
    loop: true,
    nav: true,
    navText: [
      '<span></span>',
      '<span></span>',
    ],
    dots: true,
    items: 4,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    margin: 20,
    responsive: {
      0: {
        items: 2,
      },
      991: {
        items: 4,
      },
    },
  });

  $('.carousel-partners').owlCarousel({
    smartSpeed: 1000,
    loop: true,
    nav: false,
    dots: false,
    items: 1,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    margin: 20,
  });

  $('.carousel-partners-2').owlCarousel({
    smartSpeed: 1000,
    loop: true,
    nav: true,
    dots: false,
    items: 4,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    margin: 20,
    responsive: {
      0: {
        dots: false,
        nav: true,
        items: 3,
        center: true,
        margin: 0,
        autoplay: false,
      },
    },
  });

  $('.carousel-quotes').owlCarousel({
    smartSpeed: 1000,
    loop: true,
    navText: [
      '<span></span>',
      '<span></span>',
    ],
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    margin: 20,
    responsive: {
      0: {
        dots: true,
        nav: false,
        items: 1,
      },
      991: {
        nav: true,
        dots: true,
        items: 1,
      },
    },
  });

  $('.carousel-detail-images').owlCarousel({
    smartSpeed: 1000,
    loop: true,
    navText: [
      '<span></span>',
      '<span></span>',
    ],
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    margin: 20,
    items: 1,
    responsive: {
      0: {
        nav: false,
        dots: true,
      },
      991: {
        nav: false,
        dots: true,
      },
    },
  });

  $('.carousel-preview-articles').owlCarousel({
    smartSpeed: 1000,
    loop: true,
    navText: [
      '<span></span>',
      '<span></span>',
    ],
    margin: 30,
    responsive: {
      0: {
        items: 1,
        dots: true,
        nav: false,
        // stagePadding: 50,
        // autoWidth: true,
        // margin: 10,
        // center: true,
      },
      991: {
        items: 2,
        dots: true,
        nav: true,
      },
    },
  });

  $('.carousel-products').owlCarousel({
    smartSpeed: 500,
    nav: true,
    navText: [
      '<span></span>',
      '<span></span>',
    ],
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        dots: true,
        nav: false,
        items: 1,
        stagePadding: 30,
        center: true,
        margin: 20,
      },
      991: {
        dots: true,
        nav: true,
        items: 3,
        slideBy: 3,
        margin: 30,
      },
    },
  });

  $('.carousel-article-detail-images').owlCarousel({
    smartSpeed: 1000,
    loop: true,
    navText: [
      '<span></span>',
      '<span></span>',
    ],
    margin: 30,
    items: 1,
    responsive: {
      0: {
        dots: false,
        nav: true,
      },
      991: {
        dots: false,
        nav: true,
      },
    },
  });

  $('.bestsol-carusel').owlCarousel({
    smartSpeed: 500,
    nav: false,
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        dots: true,
        nav: false,
        items: 1,
        stagePadding: 10,
        center: false,
        margin: 0,
        autoplay: false,
      },
      991: {
        dots: true,
        nav: true,
        items: 4,
        slideBy: 4,
        margin: 30,
        autoplay: true,
      },
    },
  });

  if ($(window).width() < 992) {
    $('.bestsol-carusel-values').owlCarousel({
      smartSpeed: 500,
      nav: false,
      loop: true,
      autoplay: false,
      autoplayTimeout: 3000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          dots: true,
          nav: false,
          items: 1,
          stagePadding: 10,
          center: false,
          margin: 0,
          autoplay: false,
        },
        991: {
          dots: true,
          nav: true,
          items: 4,
          slideBy: 4,
          margin: 30,
          autoplay: true,
        },
      },
    });
  }
});
