const accordionListEl = document.querySelectorAll('.accordion-list');

if (accordionListEl.length) {
  accordionListEl.forEach((accordionList) => {
    const accordionListItemEl = accordionList.querySelectorAll('.accordion-list-item');

    accordionListItemEl.forEach((accordionListItem) => {
      const accordionListItemHeadEl = accordionListItem.querySelector('.accordion-list-item-head');

      if (accordionListItemHeadEl) {
        accordionListItemHeadEl.addEventListener('click', (evt) => {
          evt.preventDefault();
          if (!accordionListItem.classList.contains('active')) {
            accordionListItemEl.forEach((oo) => {
              oo.classList.remove('active');
            });
            accordionListItem.classList.add('active');
          } else {
            evt.stopPropagation();
            // accordionListItem.classList.remove('active');
          }
        });
      }
    });
  });
}

const dataJsImg = document.querySelectorAll('[data-js-img]');
if (dataJsImg.length) {
  dataJsImg.forEach((oo) => {
    oo.addEventListener('click', (evt) => {
      evt.preventDefault();
      // const target = evt.currentTarget;
      // const parentTarget = target.parentNode;
      const jsTarget = document.querySelector(oo.dataset.jsTarget);
      if (jsTarget) {
        jsTarget.classList.add('hidden');
        jsTarget.setAttribute('src', oo.dataset.jsImg);
        setTimeout(() => jsTarget.classList.remove('hidden'), 300);
      }
    });
  });
}
